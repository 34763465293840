import { useEffect } from 'react'

import useRegion from '../hooks/useRegion'
import redesignedFooter from '../styles/components/footer/redesignedFooter'
import initializeAds from '../utils/initializeAds'
import FooterSection from './FooterSection/FooterSection'
import Header from './Header/Header'

import { useAmp } from 'next/amp'
import Head from 'next/head'
import Script from 'next/script'

const Layout = ({
  hideLayout,
  children,
  navigation,
  banner,
  footerNY,
  newslettersList,
  marketingNavigation,
  weatherConditions
}) => {
  const region = useRegion()
  const isAmp = useAmp()

  const mountHeader = !isAmp && (region.name === 'newyork' || (!hideLayout && navigation))

  useEffect(() => {
    initializeAds(region)
  }, [])

  return (
    <>
      <Head>
        {!isAmp && (
          <>
            <link rel='preload' href='//googleads.github.io/videojs-ima/dist/videojs.ima.css' type='style' />
          </>
        )}
      </Head>
      {mountHeader && (
        <Header
          regionName={region.name}
          navigation={navigation}
          banner={banner}
          newslettersList={newslettersList}
          marketingNavigation={marketingNavigation}
          weatherConditions={weatherConditions}
        />
      )}
      {children}
      {!isAmp && (
        <>
          <script type='text/javascript' src={process.env.AD_BUNDLE}></script>
          <Script type='text/javascript' src='https://platform.twitter.com/widgets.js' defer></Script>
          <script src='//imasdk.googleapis.com/js/sdkloader/ima3.js'></script>
        </>
      )}
      {!hideLayout && (
        <>
          <FooterSection footer={navigation?.footer || footerNY?.links} />
          <style jsx>{redesignedFooter}</style>
        </>
      )}

      {!isAmp && (
        <Script
          dangerouslySetInnerHTML={{
            __html: `
        window._taboola = window._taboola || [];
      `
          }}
        />
      )}
    </>
  )
}

export default Layout
